<style src='./assets/css/bootstrap.css'></style>
<style src='./assets/css/owl.theme.default.css'></style>
<style src='./assets/css/owl.carousel.css'></style>
<style src='./assets/css/animate.css'></style>
<style src='./assets/css/boxicons.css'></style>
<style src='./assets/css/magnific-popup.css'></style>
<style src='./assets/css/flaticon.css'></style>
<style src='./assets/css/meanmenu.css'></style>
<style src='./assets/css/nice-select.css'></style>
<style src='./assets/css/odometer.css'></style>
<style src='./assets/css/responsive.css'></style>
<style src='./assets/css/stats.css'></style>

<style src='./assets/css/style.scss' lang="scss"></style>



<!-- Fontawesome -->
<style src='./assets/css/fa-regular.css'></style>
<style src='./assets/css/fa-solid.css'></style>
<style src='./assets/css/fa-light.css'></style>
<style src='./assets/css/fa-fontawesome.css'></style>

<template>

  <NavBar/>

  <router-view/>

  <Footer/>

  <!-- bad workaround because of an amplify login bug when reload the page - include login module here and hide it -->
  <div style="display: none;">
  <Login />
  </div>

<!--  <scriptx src="/assets/js/jquery.min.js"></scriptx> &lt;!&ndash; because the template may use it, but lets see if we kill it later &ndash;&gt;-->
  <scriptx src="/assets/js/bootstrap.bundle.min.js"></scriptx>
<!--  <scriptx src="/assets/js/meanmenu.min.js"></scriptx>&lt;!&ndash; also for menu slide-in &ndash;&gt;-->
<!--  <scriptx src="/assets/js/owl.carousel.min.js"></scriptx> &lt;!&ndash; for detailadv &ndash;&gt;-->
  <scriptx src="/assets/js/nice-select.min.js"></scriptx><!-- also for menu slide-in -->
<!--  <scriptx src="/assets/js/magnific-popup.min.js"></scriptx>-->
<!--  <scriptx src="/assets/js/jarallax.min.js"></scriptx>-->
<!--  <scriptx src="/assets/js/appear.min.js"></scriptx>-->
<!--  <scriptx src="/assets/js/odometer.min.js"></scriptx>-->
<!--  <scriptx src="/assets/js/smoothscroll.min.js"></scriptx>-->
<!--  <scriptx src="/assets/js/form-validator.min.js"></scriptx>-->
<!--  <scriptx src="/assets/js/contact-form-script.js"></scriptx>-->
<!--  <scriptx src="/assets/js/ajaxchimp.min.js"></scriptx>-->
  <scriptx src="/assets/js/wow.min.js"></scriptx>
<!--  <scriptx src="/assets/js/custom.js"></scriptx>&lt;!&ndash; also for menu slide-in &ndash;&gt;-->
  <scriptx src="/assets/js/mailerlite.js"></scriptx>

  <!--hidden mailerlite form because of CORS required-->
  <div id="mailerliteForm">
    <div class="ml-embedded" data-form="9YI3JQ" style="display: none"></div>
  </div>

  <Scripts/>
</template>




<script>

import NavBar from '@/views/navigation/navbar.vue'
import Footer from '@/views/navigation/footer.vue'
import Login from '@/views/Login.vue'
import { Auth  } from 'aws-amplify';
import Scripts from '@/views/Scripts.vue'





export default {
  components: {
    NavBar,
    Footer,
    Login,
    Scripts
  },

  created() {
    console.log('created()')
    try{
      Auth.wrapRefreshSessionCallback()
    }catch(error){
      console.log('Signin error: ', error )
    }


  },

  mounted(){

    if (!document.URL.indexOf("localhost")){
      alert("STOP! This page is under construction. DO NOT buy anything at the moment!")
    }

  },

  methods: {

  }


}

</script>
